import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../apis/api";

const ReferredUsers = ({ id }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/referred-users/${id}`);
      setUsers(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  if (loading === true) {
    return null;
  }

  if (users.length === 0) {
    return null;
  }

  return (
    <div className="w-full  p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10">
      <h1 className="text-xl font-semibold text-primary">Referred users</h1>
      {users
        .slice()
        .reverse()
        .map((item) => {
          const goTo = () => {
            navigate(`/restricted-page/admin/users/${item.id}`);
            window.location.reload();
          };
          return (
            <Link
              onClick={goTo}
              to={`/restricted-page/admin/users/${item.id}`}
              className="text-xs text-primary w-full flex items-center justify-between py-1 border-b border-primary"
            >
              <span>{item.username}</span>
              <span>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(parseInt(item.wallet))}
              </span>
            </Link>
          );
        })}
    </div>
  );
};

export default ReferredUsers;
