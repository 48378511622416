import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../apis/api";
import Spinner from "../../components/spinners/Spinner";
import globalStyles from "../../global/globalStyles";

const AdminVerification = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await api.get("/user-with-id");
      setUsers(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  if (loading === true) {
    return (
      <div className={globalStyles.pageDashboard}>
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Manage Users Verification
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }
  if (users.length === 0) {
    return (
      <div className={globalStyles.pageDashboard}>
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Manage Users Verification
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10 text-xs text-primary">
            <span>No user has applied yet.</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={globalStyles.pageDashboard}>
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Manage Users Verification
          </span>
        </div>
        <div className="w-full flex flex-col">
          {users
            .slice()
            .reverse()
            .map((item) => {
              return (
                <Link
                  to={`/restricted-page/admin/users-verification/${item.id}`}
                  key={item.id}
                  className="w-full p-1 h-12 bg-gray-50 transition-all ease-in-out grid grid-cols-2 text-primary max-lg:gap-2 gap-1 border-b border-primary/20"
                >
                  <div className="w-full h-full flex flex-col px-2 justify-center items-start">
                    <span className="text-sm font-medium capitalize">
                      {item.username}
                    </span>
                    <span className="text-xs font-extralight">
                      {item.email}
                    </span>
                  </div>
                  <div className="w-full h-full flex px-2 justify-end items-center">
                    Tier {item.level}
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AdminVerification;
