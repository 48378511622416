import React from "react";
import globalStyles from "../../global/globalStyles";
import Footer from "../../components/home/Footer";

const About = () => {
  return (
    <div className="w-full flex flex-col pt-[9vh] text-gray-400">
      <div className="w-full bg-why bg-no-repeat bg-cover h-48 overflow-hidden">
        <div className="w-full h-full backdrop-blur p-8 flex items-center justify-start text-4xl lg:text-5xl font-semibold text-white">
          <div
            data-aos="fade-up"
            className="flex w-fit h-fit flex-col gap-3 items-end"
          >
            <span>About Us</span>
            <div className="w-24 h-1 bg-primary" />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col p-6">
        <h2 className={globalStyles.title}>Our Vision</h2>
        <p className="ont-light text-justify my-5">
          At Alpha Assets, we envision a future where everyone has the tools and
          knowledge to unlock the full potential of their financial well-being.
          Our mission is to democratize access to smart investment solutions,
          making sophisticated trading strategies and technologies accessible to
          investors of all levels.
        </p>
        <h2 className={globalStyles.title}>Who We Are</h2>
        <p className="font-light text-justify my-5">
          Founded in 2022, Alpha Assets is a dynamic and innovative investment
          platform that merges cutting-edge technology with a passion for
          financial empowerment. Our team comprises seasoned professionals in
          finance, artificial intelligence, and technology, united by a shared
          commitment to revolutionize the way people invest.
        </p>
        <div className="w-full flex flex-col">
          <h2 className={globalStyles.title}>Our Philosophy</h2>
          <h4 className="font-semibold text-lg text-primary my-1">
            Harnessing the Power of AI
          </h4>
          <p className="font-light text-justify mb-10">
            We believe in the transformative power of artificial intelligence.
            Our proprietary AI algorithms are designed to navigate the
            complexities of financial markets, providing users with data-driven
            insights and automated trading strategies. By combining human
            expertise with machine intelligence, we aim to redefine the
            landscape of investment opportunities.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Empowering Through Education
          </h4>
          <p className="font-light text-justify mb-10">
            Knowledge is the cornerstone of successful investing. At Alpha
            Assets, we are dedicated to empowering our users with the
            information they need to make informed decisions. From
            beginner-friendly guides to advanced trading strategies, our
            educational resources are designed to support your journey towards
            financial literacy.
          </p>
        </div>
        <div className="w-full flex flex-col">
          <h2 className={globalStyles.title}>What Sets Us Apart</h2>
          <h4 className="font-semibold text-lg text-primary my-1">
            Innovation in Action
          </h4>
          <p className="font-light text-justify mb-10">
            Alpha Assets stands at the forefront of financial innovation. We
            continuously push the boundaries of what's possible, leveraging the
            latest technologies to create a platform that adapts to the dynamic
            nature of global markets. Our commitment to innovation is reflected
            in the tools and features we offer to our users.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Customer-Centric Approach
          </h4>
          <p className="font-light text-justify mb-10">
            Your success is our success. We prioritize the needs and
            satisfaction of our users above all else. Our customer-centric
            approach extends from the design of our platform to the quality of
            our customer support. We're not just a service provider; we're your
            partners on the path to financial success.
          </p>
        </div>
        <div className="w-full flex flex-col">
          <h2 className={globalStyles.title}>Our Commitment</h2>
          <h4 className="font-semibold text-lg text-primary my-1">
            Security and Trust
          </h4>
          <p className="font-light text-justify mb-10">
            We understand the importance of trust in the world of finance.
            That's why we go above and beyond to ensure the security of your
            investments and personal information. Our platform is built on
            robust security measures, employing encryption and authentication
            protocols to safeguard your assets.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Transparency and Integrity
          </h4>
          <p className="font-light text-justify mb-10">
            Transparency is the foundation of a strong partnership. We are
            committed to providing clear, honest, and transparent information
            about our services, fees, and performance. Our integrity is
            non-negotiable, and we strive to earn and maintain your trust every
            day.
          </p>
        </div>
        <h2 className={globalStyles.title}>Join Us on the Journey</h2>
        <p className="font-light text-justify mb-5">
          Whether you're a seasoned investor or just starting, Alpha Assets
          invites you to join us on a journey of financial empowerment. Explore
          the world of AI-driven trading, gain knowledge from our educational
          resources, and take control of your financial future.
        </p>
        <p className="font-light text-justify mb-10">
          Ready to experience the next level of intelligent investing? Sign up
          with Alpha Assets today and become a part of a community that believes
          in the power of informed decisions and smart investments. Together,
          let's redefine the future of finance!
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default About;
