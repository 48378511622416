import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import globalStyles from "../../global/globalStyles";
import videoSource from "../images/tradeBackground.mp4";

const Section2 = () => {
  const auth = useSelector((state) => state.user.auth);
  const navigate = useNavigate();
  const handleClick = () => {
    if (auth) {
      navigate("/dashboard");
    } else {
      navigate("/signup");
    }
  };
  const [index, setIndex] = useState(0);
  const [visible, setVisible] = useState(true);
  const title = ["Welcome to", "Empower Your Wealth", "Your Personal AI"];
  const title2 = ["Alpha Assets", "With AI", "Investment Assistant"];
  const body = [
    "Your Trusted Partner in Smart Investing! Harness the power of AI bots to supercharge your investments. Say goodbye to guesswork and hello to data-driven decision-making. Join us on a journey to financial success like never before.",
    "At Alpha Assets, we're revolutionizing the world of investing. Our AI bots work tirelessly to analyze markets, identify trends, and execute trades on your behalf. Take control of your financial future with cutting-edge technology.",
    "Introducing Alpha Assets, your personal gateway to AI-driven investments. Our smart bots are here to make trading easier and more profitable for you. Say goodbye to sleepless nights and hello to a smarter way to invest.",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setVisible(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % title.length);
        setVisible(true);
      }, 1000); // Match the timeout duration to the CSS transition duration
    }, 7000);

    return () => clearInterval(interval);
  }, [title.length]);

  return (
    <div className="w-full h-screen bg-why bg-cover bg-center">
      <div className="w-full h-screen bg-black/20 backdrop-blur-3xl flex max-lg:flex-col">
        <div className="absolute inset-0 z-0">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          >
            <source src={videoSource} type="video/mp4" />
          </video>
        </div>
        <div
          className={`w-full h-full backdrop-blur-md flex flex-col justify-center items-center gap-5 p-5  pt-[10vh]`}
        >
          <div
            className={`relative lg:w-1/2 w-5/6 h-3/5 lg:p-10 flex flex-col p-5 justify-center ${
              visible ? "fade-enter-active" : "fade-exit-active"
            }`}
          >
            <h2
              className="font-semibold text-5xl max-lg:text-3xl text-white leading-normal mb-5"
            >
              {title[index]} <br />
              <span className="text-primary">{title2[index]}</span>
            </h2>
            <p className="text-sm text-white text-left tracking-wide mb-5">
              {body[index]}
            </p>
            <div className="w-full flex justify-start max-lg:justify-center mb-5 z-10">
              <button onClick={handleClick} className={globalStyles.button}>
                Get started
              </button>
            </div>
            <div className="border-l-8 w-full h-1/5 border-blue-500 absolute top-0 left-0"></div>
            <div className="border-r-8 w-full h-1/5 border-blue-500 absolute bottom-0 right-0"></div>
            <div className="border-t-8 w-full h-full border-blue-500 absolute top-0 left-0"></div>
            <div className="border-b-8 w-full h-full border-blue-500 absolute top-0 right-0"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
