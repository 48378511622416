import React, { useEffect, useState } from "react";
import api from "../../apis/api";
import Modal from "../../components/modals/Modal";
import Spinner from "../../components/spinners/Spinner";
import globalStyles from "../../global/globalStyles";

const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const res = await api.get("/user-transactions");
      setTransactions(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const openModal = (transaction) => {
    setSelectedDeposit(transaction);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function getStatusDot(status) {
    let dotColor = "";

    if (status === "pending") {
      dotColor = "#fb923c";
    } else if (status === "confirmed") {
      dotColor = "#22c55e";
    } else if (status === "failed") {
      dotColor = "#ef4444";
    }

    return (
      <div className="flex items-center gap-0.5">
        <div
          style={{
            backgroundColor: dotColor,
          }}
          className="rounded-3xl w-1.5 h-1.5"
        ></div>
        <span className="capitalize text-xss text-primary">{status}</span>
      </div>
    );
  }

  const getDateAndTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString();

    return { date, time };
  };

  if (loading === true) {
    return (
      <div className={globalStyles.pageDashboard}>
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Transaction history
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  if (transactions.length === 0) {
    return (
      <div className={globalStyles.pageDashboard}>
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Transaction History
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10 text-primary text-xs">
            No transactions yet.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={globalStyles.pageDashboard}>
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold text-primary">
            Transaction History
          </span>
        </div>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search Transaction ID or Date (DD/MM/YYYY)"
          className={globalStyles.input}
        />

        {/*body*/}
        <div className="w-full flex flex-col">
          {transactions
            .filter((transaction) => {
              const formattedDate = new Date(
                transaction.created_at
              ).toLocaleDateString("en-GB");
              return (
                transaction.transaction_id
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                formattedDate.includes(searchTerm.toLowerCase())
              );
            })
            .slice()
            .reverse()
            .map((item) => {
              const formattedNumber = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.amount);
              const { date, time } = getDateAndTime(item.created_at);
              return (
                <div
                  onClick={() => openModal(item)}
                  key={item.id}
                  className="w-full px-1 h-10 bg-gray-50 hover:bg-gray-100 transition-all ease-in-out grid grid-cols-2 text-primary border-b border-primary/20"
                >
                  <div className="w-full flex flex-col justify-center items-start">
                    <span className="text-xs">
                      {item.type === "deposit" || item.type === "bonus"
                        ? "+"
                        : "-"}
                      {formattedNumber}
                    </span>
                    <span className="text-xxs">
                      {date}, {time}
                    </span>
                  </div>
                  <div className="w-full flex flex-col justify-center items-end">
                    <span className="text-xs capitalize">{item.type}</span>
                    <span className="text-xxs">
                      {getStatusDot(item.status)}
                    </span>
                  </div>
                </div>
              );
            })}
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            {selectedDeposit && (
              <div className="w-full p-5 bg-white shadow flex flex-col gap-2 items-center z-10">
                <h5 className="text-primary w-full text-center text-xs">
                  Transaction Details
                </h5>
                <h2 className="text-primary w-full text-center text-3xl font-bold">
                  {selectedDeposit.type === "deposit" ||
                  selectedDeposit.type === "bonus"
                    ? "+"
                    : "-"}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(selectedDeposit.amount)}
                </h2>
                <div className="w-full flex justify-center">
                  {getStatusDot(selectedDeposit.status)}
                </div>
                {selectedDeposit.type === "deposit" ||
                selectedDeposit.type === "withdrawal" ? (
                  <div className="w-full flex justify-between text-primary text-xs items-center">
                    <span>Network:</span>
                    <span className="text-xxs px-1 py-0.5 bg-primary text-white rounded cursor-pointer">
                      {selectedDeposit.payment_method}
                    </span>
                  </div>
                ) : (
                  ""
                )}

                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Transaction ID:</span>
                  <span className="text-xxs">{selectedDeposit.transaction_id}</span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Date:</span>
                  <span>{getDateAndTime(selectedDeposit.created_at).date}</span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Time:</span>
                  <span>{getDateAndTime(selectedDeposit.created_at).time}</span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Type:</span>
                  <span className="capitalize">{selectedDeposit.type}</span>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
