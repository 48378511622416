import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "./Modal";
import globalStyles from "../../global/globalStyles";

const BuyPlanModal = ({ id, item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Bitcoin");
  const navigate = useNavigate();

  const save = () => {
    sessionStorage.setItem("payment_method", paymentMethod);
    sessionStorage.setItem("amount", amount);
    sessionStorage.setItem("plan_id", id);

    if (paymentMethod === "Bitcoin") {
      closeModal();
      navigate("/make-deposit/btc");
    } else if (paymentMethod === "Litecoin") {
      closeModal();
      navigate("/make-deposit/lite");
    } else if (paymentMethod === "Ethereum") {
      closeModal();
      navigate("/make-deposit/eth");
    } else {
      closeModal();
      navigate("/make-deposit/xrp");
    }
  };

  const makeDeposit = (e) => {
    e.preventDefault();
    if (amount === "") {
      toast.warning("Please enter an amount to proceed");
    } else if (
      amount < parseInt(item.min_amount) ||
      amount > parseInt(item.max_amount)
    ) {
      toast.warning("Please enter a valid amount for this plan");
    } else {
      save();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <button onClick={openModal} className={globalStyles.buttonFull}>
        Buy
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form onSubmit={makeDeposit} className={globalStyles.formModal}>
          <h1 className="text-xl font-semibold text-primary">Make a deposit</h1>
          <input
            type="number"
            className={globalStyles.input}
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <select
            className={globalStyles.inputSelect}
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="Bitcoin">Bitcoin</option>
            <option value="Ripple">Ripple</option>
            <option value="Litecoin">Litecoin</option>
            <option value="Ethereum">Ethereum</option>
          </select>
          <button className={globalStyles.buttonFull}>
            Deposit{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(amount)}
          </button>
        </form>
      </Modal>
    </>
  );
};

export default BuyPlanModal;
