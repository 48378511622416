import React, { useState } from "react";
import useFetchPlan from "../../hooks/useFetchPlan";
import Spinner from "../../components/spinners/Spinner";
import BuyFromAmountModal from "../../components/modals/BuyFromAmountModal";
import { useSelector } from "react-redux";
import globalStyles from "../../global/globalStyles";

const BuyWithBalance = () => {
  const { plan, loadPlan } = useFetchPlan();
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state.user.profile);

  if (loading) {
    return (
      <div className={globalStyles.pageDashboard}>
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">Buy Plan</span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  if (loadPlan) {
    return (
      <div className={globalStyles.pageDashboard}>
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">Buy Plan</span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={globalStyles.pageDashboard}>
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Buy Plan (balance)
          </span>
        </div>
        {/*body*/}
        <div className="w-full text-right text-xs text-primary">
          Balance:{" "}
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(profile.wallet)}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 ">
          {plan.map((item) => {
            return (
              <div key={item.id} className={globalStyles.planCard}>
                <h3 className="text-xl font-semibold text-primary capitalize">
                  {item.title} plan
                </h3>
                <div className="w-full flex flex-col text-xs text-gray-400">
                  <div className={globalStyles.planColumn}>
                    <span>Return on Investment</span>
                    <span>{item.roi}%</span>
                  </div>
                  <div className={globalStyles.planColumn}>
                    <span>Duration (in days)</span>
                    <span>{item.duration} days</span>
                  </div>
                  <div className={globalStyles.planColumn}>
                    <span>Total return</span>
                    <span>
                      {item.duration * item.roi}%
                    </span>
                  </div>
                </div>
                <div className="w-full flex justify-center gap-2 items-center text-base lg:text-sm text-primary font-semibold">
                  <span>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.min_amount)}
                  </span>
                  <span>-</span>
                  <span>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.max_amount)}
                  </span>
                </div>
                <BuyFromAmountModal
                  item={item}
                  id={item.id}
                  setLoading={setLoading}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BuyWithBalance;
