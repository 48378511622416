import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Spinner from "../spinners/Spinner";
import globalStyles from "../../global/globalStyles";

const GiveBonus = ({ id }) => {
  const [loading1, setLoading1] = useState(false);
  const [bonus, setBonus] = useState("");

  const giveBonus = async (e) => {
    e.preventDefault();
    setLoading1(true);
    const user_id = id;
    const amount = bonus;
    try {
      const res = await api.post("/create-transaction-bonus", {
        amount,
        user_id,
      });
      setLoading1(false);
      window.location.reload();
      setBonus("");
      toast.success(res.data.message);
      toast.success("Bonus successful");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setLoading1(false);
    }
  };
  return (
    <form onSubmit={giveBonus} className={globalStyles.formModal}>
      <h1 className="text-xl font-semibold text-primary">Give Bonus</h1>
      <input
        type="number"
        className={globalStyles.input}
        placeholder="Amount"
        value={bonus}
        onChange={(e) => setBonus(e.target.value)}
      />
      {loading1 ? (
        <Spinner />
      ) : (
        <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
          Give bonus
        </button>
      )}
    </form>
  );
};

export default GiveBonus;
