import React from "react";
import TotalDeposit from "../../components/dashboardParts/TotalDeposit";
import TotalEarnings from "../../components/dashboardParts/TotalEarnings";
import TotalPlans from "../../components/dashboardParts/TotalPlans";
import TotalWithdrawal from "../../components/dashboardParts/TotalWithdrawal";
import ReferralWidget from "../../components/userParts/ReferralWidget";
import TradingViewMarquee from "../../components/widgets/TradingViewMarquee";
import TradingViewWidget from "../../components/widgets/TradingViewWidget";
import useFetchMe from "../../hooks/useFetchMe";
import ShowActivePlans from "../../components/dashboardParts/ShowActivePlans";
import MessageDisplay from "../../components/messages/MessageDisplay";

const Dashboard = () => {
  const { profile, loadUser } = useFetchMe();

  return (
    <div className="w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5 pb-10">
      <MessageDisplay />
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold text-primary">Dashboard</span>
        </div>
        {/*body*/}
        <div className="w-full h-fit flex flex-col">
          <TradingViewMarquee />
        </div>
        <div className="flex w-full bg-gray-50 h-36 justify-between items-center px-5">
          <div className="text-gray-400 max-lg:text-xs text-2xl font-extralight tracking-wide">
            <span className="capitalize">
              {loadUser
                ? "Welcome back."
                : `Welcome back, ${profile.firstname}.`}
            </span>
          </div>
          <div className="flex flex-col justify-center gap-1 items-end">
            <h5 className="text-primary font-semibold text-sm">
              Account Balance
            </h5>
            <h5 className="text-gray-400 font-extralight text-3xl">
              {loadUser
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format((0).toFixed(2))
                : new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(parseFloat(profile.wallet).toFixed(2))}
            </h5>
          </div>
        </div>
        <ShowActivePlans />
        <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-5">
          <TotalDeposit />
          <TotalWithdrawal />
          <TotalEarnings />
          <TotalPlans />
        </div>
        <ReferralWidget profile={profile} />
        <TradingViewWidget />
      </div>
    </div>
  );
};

export default Dashboard;
