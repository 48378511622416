import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Modal from "./Modal";
import globalStyles from "../../global/globalStyles";

const BuyFromAmountModal = ({ id, item, setLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const profile = useSelector((state) => state.user.profile);
  const user_id = profile.id;
  const type = "withdrawal";
  const payment_method = "Account Balance";
  const plan_id = id;
  const transaction = {
    amount,
    payment_method,
    user_id,
    type,
    plan_id,
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const makeDeposit = async (e) => {
    e.preventDefault();
    if (amount === "") {
      toast.warning("Please enter an amount to proceed");
    } else if (
      amount < parseInt(item.min_amount) ||
      amount > parseInt(item.max_amount)
    ) {
      toast.warning("Please enter a valid amount for this plan");
    } else {
      setLoading(true);
      try {
        await api.post(
          "/create-transaction-dep-by-amount",
          transaction
        );
        toast.success("Plan purchased");
        navigate("/dashboard");
      } catch (error) {
        toast.error(error.response.data.message);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <button
        onClick={openModal}
        className={globalStyles.buttonFull}
      >
        Buy
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form
          onSubmit={makeDeposit}
          className={globalStyles.formModal}
        >
          <h1 className={globalStyles.title}>
            Buy {item.title}
          </h1>
          <input
            type="number"
            className={globalStyles.input}
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <button className={globalStyles.buttonFull}>
            Buy{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(amount)}
          </button>
        </form>
      </Modal>
    </>
  );
};

export default BuyFromAmountModal;
