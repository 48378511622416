import React, { useEffect, useRef } from "react";

const TradingViewMarquee = () => {
  const container = useRef();
  const scriptAdded = useRef(false); // Add a ref to track if script is added

  useEffect(() => {
    // Check if script has already been added
    if (!scriptAdded.current) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "symbols": [
    {
      "proName": "FX_IDC:EURUSD",
      "title": "EUR to USD"
    },
    {
      "proName": "BITSTAMP:BTCUSD",
      "title": "Bitcoin"
    },
    {
      "proName": "BITSTAMP:ETHUSD",
      "title": "Ethereum"
    },
    {
      "description": "Ripple",
      "proName": "KRAKEN:XRPUSD"
    },
    {
      "description": "Litecoin",
      "proName": "BINANCE:LTCUSD"
    },
    {
      "description": "Cardano",
      "proName": "BINANCE:ADAUSD"
    },
    {
      "description": "Solana",
      "proName": "BINANCE:SOLUSDT"
    }
  ],
  "showSymbolLogo": true,
  "isTransparent": true,
  "displayMode": "adaptive",
  "colorTheme": "light",
  "locale": "en"
        }`;
      container.current.appendChild(script);

      // Update the ref to indicate script is added
      scriptAdded.current = true;
    }
  }, []);
  return (
    <div class="tradingview-widget-container" ref={container}>
      <div class="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default TradingViewMarquee;
