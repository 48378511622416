import React from "react";
import globalStyles from "../../global/globalStyles";
import Footer from "../../components/home/Footer";

const Terms = () => {
  return (
    <div className="w-full flex flex-col pt-[9vh] text-gray-400">
      <div className="w-full bg-why bg-no-repeat bg-cover h-48 overflow-hidden">
        <div className="w-full h-full backdrop-blur p-8 flex items-center justify-start text-3xl md:text-4xl lg:text-5xl font-semibold text-white">
          <div
            data-aos="fade-up"
            className="flex w-fit h-fit flex-col gap-3 items-end"
          >
            <span>Terms and Conditions</span>
            <div className="w-24 h-1 bg-primary" />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col p-6">
        <h2 className={globalStyles.title}>Acceptance of Terms</h2>
        <p className="font-light text-justify mb-10">
          Welcome to Alpha Assets. These Terms and Conditions ("Terms") govern
          your access to and use of our website and services. By accessing or
          using Alpha Assets, you agree to comply with and be bound by these
          Terms. If you do not agree with any part of these Terms, please do not
          use our website or services.
        </p>
        <h2 className={globalStyles.title}>Account Registration</h2>
        <p className="font-light text-justify mb-10">
          To use certain features of Alpha Assets, you may be required to create
          an account. You agree to provide accurate, current, and complete
          information during the registration process and to update such
          information to keep it accurate, current, and complete.
        </p>
        <h2 className={globalStyles.title}>Use of Services</h2>
        <p className="font-light text-justify mb-10">
          You agree to use Alpha Assets for lawful purposes only and in
          compliance with all applicable laws and regulations. You are
          responsible for ensuring that your use of the services does not
          violate any laws or regulations.
        </p>
        <h2 className={globalStyles.title}>Risks of Investing</h2>
        <p className="font-light text-justify mb-10">
          Investing in cryptocurrencies and stocks involves risks. Alpha Assets
          does not guarantee any specific financial outcome, and past
          performance is not indicative of future results. You acknowledge and
          accept the inherent risks of investing, and you are solely responsible
          for your investment decisions.
        </p>
        <h2 className={globalStyles.title}>Intellectual Property</h2>
        <p className="font-light text-justify mb-10">
          All content, logos, trademarks, and other intellectual property on
          Alpha Assets are the property of Alpha Assets or its licensors. You
          may not use, reproduce, or distribute any content from Alpha Assets
          without our prior written permission.
        </p>
        <h2 className={globalStyles.title}>Privacy</h2>
        <p className="font-light text-justify mb-10">
          Your use of Alpha Assets is subject to our Privacy Policy. By using
          our services, you consent to the collection, use, and disclosure of
          your information as described in the Privacy Policy.
        </p>
        <h2 className={globalStyles.title}>Limitation of Liability</h2>
        <p className="font-light text-justify mb-10">
          To the extent permitted by law, Alpha Assets and its affiliates,
          officers, directors, employees, and agents will not be liable for any
          indirect, incidental, special, consequential, or punitive damages, or
          any loss of profits or revenues.
        </p>
        <h2 className={globalStyles.title}>Termination</h2>
        <p className="font-light text-justify mb-10">
          We reserve the right to terminate or suspend your account and access
          to Alpha Assets at our sole discretion, without notice, for conduct
          that we believe violates these Terms or is harmful to other users or
          Alpha Assets.
        </p>
        <h2 className={globalStyles.title}>Changes to Terms</h2>
        <p className="font-light text-justify mb-10">
          We may update these Terms from time to time. The date of the latest
          update will be displayed at the top of the page. Your continued use of
          Alpha Assets after any changes to the Terms constitutes acceptance of
          those changes.
        </p>
        <h2 className={globalStyles.title}>Contact Us</h2>
        <p className="font-light text-justify mb-10">
          If you have any questions or concerns about these Terms, please
          contact us at support@alphaassets.biz.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
