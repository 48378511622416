import React, { useEffect, useState } from "react";
import api from "../../apis/api";

const TotalEarningsByID = ({ id }) => {
  const [earning, setEarning] = useState("");
  const getEarning = async () => {
    try {
      const res = await api.get(`/get-total-earnings/${id}`);
      setEarning(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEarning();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="w-full h-20 bg-gray-50 flex px-8 items-center justify-between">
      <i className="fa-solid fa-chart-line text-3xl text-primary"></i>
      <div className="flex flex-col items-end">
        <h5 className="text-primary font-semibold text-xs">Total Earnings</h5>
        <h5 className="text-gray-400 font-extralight text-xl">
          {earning === ""
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(0)
            : new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(earning)}
        </h5>
      </div>
    </div>
  );
};

export default TotalEarningsByID;
