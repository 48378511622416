import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Spinner from "../spinners/Spinner";
import Modal from "./Modal";
import globalStyles from "../../global/globalStyles";

const LiteModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [qr_code, setQr_code] = useState(null);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const create = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("qr_code", qr_code);
    formData.append("address", address);
    try {
      const res = await api.post(`/create-lite-address`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res.data);
      toast.success(res.data.message);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          toast.error(error.response.data.message);
        } else if (typeof error.response.data.message === "object") {
          Object.values(error.response.data.message).forEach((messageArray) => {
            if (Array.isArray(messageArray)) {
              messageArray.forEach((message) => {
                toast.error(message);
              });
            } else {
              toast.error(messageArray);
            }
          });
        }
      }
      console.log(error);
    }
  };

  return (
    <>
      <button onClick={openModal} className={globalStyles.button}>
        Add address
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form onSubmit={create} className={globalStyles.formModal}>
          <h1 className="text-xl font-semibold text-primary">Create address</h1>
          <input
            type="text"
            className={globalStyles.input}
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <input
            type="file"
            className={globalStyles.inputFile}
            onChange={(e) => setQr_code(e.target.files[0])}
          />
          {loading ? (
            <Spinner />
          ) : (
            <button className={globalStyles.buttonFull}>Create</button>
          )}
        </form>
      </Modal>
    </>
  );
};

export default LiteModal;
