const globalStyles = {
  form: "w-11/12 md:w-1/2 lg:w-1/3 p-5 shadow flex flex-col gap-2 items-center z-10",
  formModal: "w-full p-5 bg-white flex flex-col gap-2 items-center z-10",
  input:
    "w-full bg-gray-50 border-gray-200 text-xs p-2 outline-none border text-primary focus:border-primary/30 placeholder:text-primary/40",
  inputTextArea:
    "w-full bg-gray-50 border-gray-200 text-xs p-2 outline-none border text-primary focus:border-primary/30 placeholder:text-primary/40 resize-none",
  inputSelect:
    "w-full bg-gray-50 border-gray-200 text-xs p-2 outline-none border text-primary focus:border-primary/30 placeholder:text-primary/40 appearance-none",
  inputFile:
    "w-full bg-gray-50 border-gray-200 text-xs p-2 outline-none border text-primary focus:border-primary/30 placeholder:text-primary/40 file:bg-primary file:text-white file:border-0 file:bg-primary file:px-4 file:py-1",
  buttonFull:
    "p-1 w-full bg-primary text-white hover:bg-white hover:text-primary hover:scale-95 transition-all ease-in-out shadow",
  buttonFullFlex:
    "p-1 w-full bg-primary text-white hover:bg-white hover:text-primary hover:scale-95 transition-all ease-in-out shadow flex justify-center items-center gap-1",
  button:
    "w-fit py-1 px-4 bg-primary text-white hover:bg-white hover:text-primary hover:scale-95 transition-all ease-in-out shadow",
  button2:
    "w-fit py-1 px-4 bg-primary text-white hover:bg-white hover:text-primary hover:scale-95 transition-all ease-in-out shadow max-lg:hidden",
  pageDashboard: "w-full min-h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0",
  pageDashboardScreen: "w-full h-screen bg-white pt-[9vh] pl-56 max-lg:pl-0",
  planCard: "w-full bg-gray-50 p-5 flex flex-col items-center gap-5",
  planColumn:
    "w-full flex justify-between items-center py-2 px-1 border-b border-primary/20",
  addressCard:
    "w-full h-10 max-lg:h-10 bg-gray-50 px-2 flex items-center text-primary hover:bg-transparent transition-all ease-in-out justify-between text-xs max-lg:text-sm border-b border-primary/20",
  depositDetailsColumn:
    "w-full flex justify-between items-center p-1 border-b border-primary/20",
  title: "text-xl font-semibold text-primary",
  homeTitle: "font-semibold text-5xl max-lg:text-3xl text-primary",
};

export default globalStyles;
