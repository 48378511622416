import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../apis/api";
import { toast } from "react-toastify";
import Spinner from "../../components/spinners/Spinner";
import globalStyles from "../../global/globalStyles";

const EnterCode = () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const email = sessionStorage.getItem("email");

  const getVerified = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/check-email-verification-code", {
        code,
        email,
      });
      setLoading(false);
      navigate("/dashboard");
      toast.success(res.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="w-full h-screen pt-[9vh] bg-white flex justify-center items-center">
      <form onSubmit={getVerified} className={globalStyles.form}>
        <h1 className="text-xl font-semibold text-primary">
          Enter verification code
        </h1>
        <input
          type="text"
          className={globalStyles.input}
          placeholder="Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        {loading ? (
          <Spinner />
        ) : (
          <button className={globalStyles.buttonFull}>Get code</button>
        )}
      </form>
    </div>
  );
};

export default EnterCode;
