import React from "react";
import { useSelector } from "react-redux";

const MessageDisplay = () => {
  const message = useSelector((state) => state.message.message);
  const messagePresent = useSelector((state) => state.message.messagePresent);
  if (messagePresent === false) {
    return null;
  }
  return (
    <div className="scrolling-container bg-primary/20">
      <div className="scrolling-text">{message.message}</div>
    </div>
  );
};

export default MessageDisplay;
