import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../apis/api";
import Country from "../../components/arrays/Country";
import Occupations from "../../components/arrays/Occupations";
import Spinner from "../../components/spinners/Spinner";
import useFetchMe from "../../hooks/useFetchMe";
import { toast } from "react-toastify";
import globalStyles from "../../global/globalStyles";

const VerifyID = () => {
  const { profile, loadUser } = useFetchMe();
  const [ssn, setSnn] = useState("");
  const [address, setAddress] = useState("");
  const [dl_front, setDl_front] = useState(null);
  const [dl_back, setDl_back] = useState(null);
  const [phonebody, setPhonebody] = useState("");
  const [countryCode, setCountryCode] = useState("1"); // Default to USA
  const [occupation, setOccupation] = useState("");
  const [salary, setSalary] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const countryCodes = Country;

  const occupationOptions = Occupations;

  const phone = "+" + countryCode + " " + phonebody;

  const salaryRangeOptions = [
    "Less than $50,000",
    "$50,000 - $100,000",
    "$100,000 - $150,000",
    "$150,000 - $200,000",
    "More than $200,000",
  ];

  const findCountryByName = (countryName) => {
    return countryCodes.find((country) => country.name === countryName);
  };

  useEffect(() => {
    const defaultCountry = findCountryByName(profile.country);
    if (defaultCountry) {
      setCountryCode(defaultCountry.phone.toString());
    }
    // eslint-disable-next-line
  }, [profile.country]);

  if (loadUser === true) {
    return (
      <div className="w-full h-screen pt-[9vh] bg-gradient-to-br from-black to-black/80 flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("phone", phone);
    formData.append("ssn", ssn);
    formData.append("address", address);
    formData.append("dl_front", dl_front);
    formData.append("dl_back", dl_back);
    formData.append("salary", salary);
    formData.append("occupation", occupation);
    try {
      const res = await api.post("/verify-to-level-3", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      navigate(-1);
      toast.info(res.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="w-full h-screen pt-[9vh] bg-white flex justify-center items-center">
      <form onSubmit={handleSubmit} className={globalStyles.form}>
        <h1 className={globalStyles.title}>Identity verification</h1>
        <input
          type="text"
          className={globalStyles.input}
          placeholder="Enter ID number"
          value={ssn}
          onChange={(e) => setSnn(e.target.value)}
        />
        <textarea
          type="text"
          className={globalStyles.inputTextArea}
          rows={3}
          placeholder="Home address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <div className="flex items-center w-full gap-1">
          <span className="text-primary">{"+" + countryCode}</span>
          <input
            type="text"
            className={globalStyles.input}
            placeholder="Enter mobile number"
            onChange={(e) => setPhonebody(e.target.value)}
          />
        </div>
        <div className="w-full flex gap-2 items-center text-primary text-xs">
          <span>Drivers License (front)</span>
          <input
            type="file"
            className={globalStyles.inputFile}
            onChange={(e) => setDl_front(e.target.files[0])}
          />
        </div>
        <div className="w-full flex gap-2 items-center text-primary text-xs">
          <span>Drivers License (back)</span>
          <input
            type="file"
            className={globalStyles.inputFile}
            onChange={(e) => setDl_back(e.target.files[0])}
          />
        </div>
        <div className="flex gap-2 w-full">
          <select
            className={globalStyles.inputSelect}
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
          >
            <option value="" disabled>
              Select Occupation
            </option>
            {occupationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            className={globalStyles.inputSelect}
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
          >
            <option value="" disabled>
              Select Salary Range
            </option>
            {salaryRangeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <button className={globalStyles.buttonFull}>Submit</button>
        )}
        <div className="bg-gray-50 text-primary flex gap-2 items-center p-2 w-full">
          <i className="fa-solid fa-info text-xs"></i>
          <p className="text-xxs leading-3 text-justify w-full">
            The ID number field is the your Social Security number if your country is
            USA, if your from Australia then use your Tax File Number, if
            you are from United Kingdom then use your National Insurance number,
            if you are from canada use your SIN/ITN. All other countries must
            use their countries valid identification number.
          </p>
        </div>
      </form>
    </div>
  );
};

export default VerifyID;
