import React from "react";
import market from "../images/market.jpg";
import analysis from "../images/analysis.jpg";
import ant from "../images/antminer.JPG";
import globalStyles from "../../global/globalStyles";

const Section1 = () => {
  return (
    <div className="min-h-screen w-full bg-white pt-[9vh]">
      <div className="w-full h-full p-4 max-sm:p-8">
        <div className="w-fll h-full grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="w-full h-full flex flex-col md:p-4 lg:p-10 justify-between gap-6">
            <div className="flex w-full flex-col items-center">
              <span className="w-full mb-5">
                <h2 data-aos="fade-right" className={globalStyles.homeTitle}>
                  How Alpha Assets Works
                </h2>
              </span>
              <p className="text-gray-400 text-sm text-justify font-extralight">
                Discover how Alpha Assets leverages AI technology to empower
                your investments. Our proprietary algorithms and AI bots work
                seamlessly to identify opportunities and execute trades on your
                behalf.
              </p>
            </div>
            <img
              data-aos="fade-right"
              src={analysis}
              alt=""
              className="w-full h-min shadow-lg"
            />
          </div>
          <div className="w-full h-full flex flex-col md:p-4 lg:p-10 justify-between gap-6">
            <img
              data-aos="fade-left"
              src={market}
              alt=""
              className="w-full h-min shadow-lg"
            />
            <div className="flex w-full flex-col items-center">
              <ul
                data-aos="fade-left"
                className="list-outside text-gray-400 font-extralight leading-8"
              >
                <li>
                  <span className="text-primary">AI Analysis: </span>
                  Our bots continuously analyze market data, identify trends,
                  and assess risk to make informed investment decisions.
                </li>
                <li>
                  <span className="text-primary">Diversification: </span>
                  We create diversified portfolios to minimize risk and maximize
                  returns.
                </li>
                <li>
                  <span className="text-primary">User Control: </span>
                  You have full control over your investment preferences and
                  risk tolerance, ensuring a personalized experience.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full gap-6 grid grid-cols-1 lg:grid-cols-2 md:p-4 lg:p-10 max-md:mt-4">
          <p className="text-gray-400 text-sm text-justify font-extralight w-full">
            Our experts execute this task with the help of Antminer and AI
            technology.
          </p>
          <img
            data-aos="fade-right"
            src={ant}
            alt=""
            className="w-full h-min shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default Section1;
