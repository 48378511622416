import React from "react";
import ContactWidget from "../../components/userParts/ContactWidget";
import ProfileWIdget from "../../components/userParts/ProfileWIdget";
import StatusWidget from "../../components/userParts/StatusWidget";
import ReferralWidget from "../../components/userParts/ReferralWidget";
import { useState } from "react";
import EditProfileModal from "../../components/modals/EditProfileModal";
import EditPasswordModal from "../../components/modals/EditPasswordModal";
import DeleteUserModal from "../../components/modals/DeleteUserModal";
import WalletStatus from "../../components/userParts/WalletStatus";
import { useSelector } from "react-redux";
import globalStyles from "../../global/globalStyles";

const Profile = () => {
  const profile = useSelector((state) => state.user.profile);
  const [showSettings, setShowSettings] = useState(false);

  return (
    <div className={globalStyles.pageDashboard}>
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">Profile</span>
        </div>
        {/*body*/}

        <div
          className={`w-full flex justify-between items-center overflow-hidden`}
        >
          <button onClick={() => setShowSettings(!showSettings)}>
            <i className="fa-solid fa-gear text-sm text-primary"></i>
          </button>
          <div
            className={`flex items-center transition-all ease-in-out ${
              showSettings ? "" : "translate-x-[120%]"
            }`}
          >
            <EditProfileModal profile={profile} />
            <EditPasswordModal />
            <DeleteUserModal profile={profile} />
          </div>
        </div>
        <div className="w-full flex gap-5 max-lg:flex-col">
          {/* profile  */}
          <ProfileWIdget profile={profile} />
          {/* contact */}
          <ContactWidget profile={profile} />
        </div>
        <div className="w-full flex gap-5 max-lg:flex-col">
          {/* level */}
          <StatusWidget profile={profile} />
          {/* referral */}
          <ReferralWidget profile={profile} />
        </div>
        <WalletStatus />
      </div>
    </div>
  );
};

export default Profile;
