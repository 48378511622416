import React from "react";
import { ReactTyped } from "react-typed";
import CountUp from "react-countup";
import globalStyles from "../../global/globalStyles";

const stats = [
  { title: "Transactions every 24 hours", value: 959, suffix: "+" },
  { title: "Assets under holding", value: 86, suffix: "K+" },
  { title: "New users annually", value: 28, suffix: "k+" },
];

const Section5 = () => {
  return (
    <div className="w-full h-screen lg:pt-[10vh] bg-why">
      <div className="w-full h-full flex flex-col justify-center items-center p-5 gap-5 backdrop-blur-sm">
        <h2 data-aos="fade-up" className={globalStyles.homeTitle}>
          Our Statistics
        </h2>
        <div
          id="stats-section"
          className="flex items-center gap-5 lg:gap-16 max-lg:flex-col mt-10"
        >
          {stats.map((item) => {
            return (
              <div className="flex flex-col gap-2 items-center">
                <ReactTyped
                  startWhenVisible
                  strings={[item.title]}
                  typeSpeed={100}
                  className="font-semibold text-gray-400"
                />
                <CountUp
                  start={0}
                  end={item.value}
                  duration={3}
                  decimals={0}
                  separator=" "
                  suffix={item.suffix}
                  className="text-primary font-extralight text-2xl"
                  enableScrollSpy={true}
                ></CountUp>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Section5;
