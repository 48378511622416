import React from "react";
import globalStyles from "../../global/globalStyles";

const items = [
  {
    title: "AI Expertise",
    text: "Our team of experts has designed AI bots that are fine-tuned for the financial markets.",
    icon: "fa-solid fa-robot",
  },
  {
    title: "Maximum Returns",
    text: "Harness the power of AI to make well-informed, data-driven investment decisions.",
    icon: "fa-solid fa-chart-line",
  },
  {
    title: "Security",
    text: "We prioritize the security of your investments, using advanced encryption and authentication methods.",
    icon: "fa-solid fa-lock",
  },
  {
    title: "24/7 Monitoring",
    text: "Our AI bots work round the clock to seize market opportunities, so you don't have to.",
    icon: "fa-solid fa-clock",
  },
];

const Section3 = () => {
  return (
    <div className="w-full  max-lg:h-fit lg:h-screen flex flex-col justify-center items-center p-5 gap-5 lg:pt-[19vh] bg-transparent">
      <h2 data-aos="fade-up" className={globalStyles.homeTitle}>
        Why Choose Alpha Assets?
      </h2>
      <p className="text-sm text-gray-400 text-left tracking-wide px-5">
        Alpha assets is the platform for anybody who wants to profit from
        trading without giving up their day job. Alpha assets helps you set up
        simple and sophisticated trading robots to make a profit autonomously
        24/7 in different market conditions.
      </p>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-5 gap-5">
        {items.map((item) => {
          return (
            <div
              key={item.title}
              data-aos="fade-right"
              className="w-full p-8 z-10 shadow-primary bg-gray-50 flex flex-col gap-2 group hover:-translate-y-2 ease-in-out transition-all text-primary text-xl"
            >
              <div className="w-full flex justify-between items-center">
                <span className="font-medium">{item.title}</span>
                <div className="w-10 h-10 bg-primary/20 flex items-center justify-center rounded-full">
                  <i className={item.icon}></i>
                </div>
              </div>
              <p className="text-gray-400 font-light text-pretty">
                {item.text}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Section3;
