import React from 'react'
import globalStyles from '../../global/globalStyles';

const ContactWidget = ({profile}) => {
  return (
    <div className="w-full p-5 bg-white flex flex-col gap-2 items-center z-10">
      <h1 className="text-xl font-semibold text-primary">
        Contact Information
      </h1>
      <div className="w-full flex gap-5">
        <span className="w-full text-xxs text-start text-primary">
          Email address
        </span>
        <span className="w-full text-xxs text-start text-primary">
          Phone number
        </span>
      </div>
      <div className="w-full flex gap-5">
        <input
          type="text"
          className={globalStyles.input}
          value={profile.email}
          disabled
        />
        <input
          type="text"
          className={globalStyles.input}
          value={profile.phone === null ? "Nil" : profile.phone}
          disabled
        />
      </div>
      <span className="w-full text-xxs text-start text-primary">
        Country of residence
      </span>
      <input
        type="text"
        className={globalStyles.input}
        value={profile.country}
        disabled
      />
      <span className="w-full text-xxs text-start text-primary">Address</span>
      <input
        type="text"
        className={globalStyles.input}
        value={profile.address === null ? "Nil" : profile.address}
        disabled
      />
    </div>
  );
}

export default ContactWidget