import React, { useState } from "react";
import globalStyles from "../../global/globalStyles";

const faq = [
  {
    question:
      "Is my personal and financial information safe with Alpha Assets?",
    answer:
      "Yes, your security is our top priority. Alpha Assets employs advanced encryption and authentication measures to ensure the safety of your personal and financial data. We adhere to stringent security standards to protect your information.",
  },
  {
    question:
      "What is the minimum investment required to get started with Alpha Assets?",
    answer:
      "Alpha Assets offers large flexibility, and the fixed minimum investment amount is $500. You can start with an amount from $500 and above that suits your budget and financial goals.",
  },
  {
    question: "How do I sign up for Alpha Assets?",
    answer:
      "Getting started with Alpha Assets is easy. Simply click on the 'Get Started' button on our homepage and go the create an account, then follow the registration process. You'll be on your way to smarter investing in no time.",
  },
  {
    question: "Do I need prior investing experience to use Alpha Assets?",
    answer:
      "No prior experience is necessary. Alpha Assets is designed to be user-friendly and suitable for both beginners and experienced investors. Our AI bots do the heavy lifting for you.",
  },
  {
    question: "How often does Alpha Assets make trades on my behalf?",
    answer:
      "Alpha Assets continuously monitors the market and makes trades when it identifies suitable opportunities. The frequency of trades ranges from 2 to 5 times a day for us tobe able to meet up with your daily returns for the plan you suscribe too.",
  },
  {
    question: "Can I withdraw my funds at any time?",
    answer:
      "Yes, you can withdraw your funds at any time. Alpha Assets provides flexibility when it comes to managing your investments. However, please note that there may be specific terms and conditions related to withdrawals.",
  },
  {
    question: " How can I contact Alpha Assets for support or assistance?",
    answer:
      "You can contact our customer support team through the chat button at the bottom right of your page. We're here to assist with any questions or concerns you may have.",
  },
];

const Faq = () => {
  const [openItems, setOpenItems] = useState(new Array(faq.length).fill(false));

  const toggleItem = (index) => {
    const updatedOpenItems = openItems.map((item, i) =>
      i === index ? !item : false
    );
    setOpenItems(updatedOpenItems);
  };

  return (
    <div className="w-full max-lg:h-fit lg:h-screen flex flex-col justify-center items-center p-5 gap-5 pt-[10vh] lg:pt-[19vh] bg-transparent">
      <h2 data-aos="fade-up" className={globalStyles.homeTitle}>
        FAQ's
      </h2>
      <div className="w-full flex flex-col">
        {faq.map((item, index) => (
          <div
            onClick={() => toggleItem(index)}
            key={index}
            className={`p-2 w-full py-5 z-10 cursor-pointer border-primary/20 border-dashed border-b ${
              openItems[index] ? "" : ""
            } transition-all ease-in-out duration-500`}
          >
            <div className="w-full flex items-center justify-between text-primary font-semibold text-base">
              <h4 className="w-5/6 ">{item.question}</h4>
              {openItems[index] ? "-" : "+"}
            </div>
            <p
              className={`text-gray-400 ${
                openItems[index] ? "h-fit" : "h-0"
              } transition-all ease-in-out duration-500 text-xs`}
            >
              {openItems[index] ? item.answer : null}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
