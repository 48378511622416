import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Spinner from "../spinners/Spinner";
import Modal from "./Modal";
import { useSelector } from "react-redux";
import globalStyles from "../../global/globalStyles";

const NewTransactionModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transaction_id, setTransaction_id] = useState("");
  const amount = sessionStorage.getItem("amount");
  // const amount = parseInt(number) + parseInt(number * 0.05);
  const payment_method = sessionStorage.getItem("payment_method");
  const plan_id = sessionStorage.getItem("plan_id");
  const profile = useSelector((state) => state.user.profile);
  const user_id = profile.id;
  const type = "deposit";
  const transaction = {
    amount,
    payment_method,
    user_id,
    type,
    plan_id,
    transaction_id,
  };
  const navigate = useNavigate();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const create = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post("/create-transaction-dep", transaction);
      sessionStorage.removeItem("amount");
      sessionStorage.removeItem("payment_method");
      closeModal();
      toast.info("Deposit processing");
      navigate("/dashboard");
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };
  return (
    <>
      <button onClick={openModal} className={globalStyles.buttonFull}>
        Paid
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="w-full p-5 bg-white shadow-md flex flex-col gap-2 items-center z-10">
          <h1 className="text-xl font-semibold text-primary">
            Have you made payment?
          </h1>
          <form onSubmit={create} className={globalStyles.formModal}>
            <input
              type="text"
              className={globalStyles.input}
              placeholder="Enter the Transaction ID"
              value={transaction_id}
              onChange={(e) => setTransaction_id(e.target.value)}
            />
            <div className="w-full flex justify-center">
              {loading ? (
                <div className="w-full flex justify-center py-3">
                  <Spinner />
                </div>
              ) : (
                <div className="w-full gap-5 flex">
                  <button
                    onClick={closeModal}
                    className={globalStyles.buttonFull}
                  >
                    No
                  </button>
                  <button className={globalStyles.buttonFull}>Paid</button>
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default NewTransactionModal;
