import React, { useState } from "react";
import Spinner from "../spinners/Spinner";
import api from "../../apis/api";
import { toast } from "react-toastify";
import globalStyles from "../../global/globalStyles";

const NewMesaage = ({ id, setMessagePresent }) => {
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const user_id = id;
    try {
      const res = await api.post(`/create-display-message`, {
        user_id,
        message,
      });
      toast.success(res.data.message);
      setmessage("");
      setMessagePresent(true);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setloading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit} className={globalStyles.formModal}>
      <h1 className="text-xl font-semibold text-primary">Add prompt message</h1>
      <input
        type="text"
        className={globalStyles.input}
        placeholder="Display message"
        value={message}
        onChange={(e) => setmessage(e.target.value)}
      />
      {loading ? (
        <Spinner />
      ) : (
        <button className={globalStyles.buttonFull}>Add</button>
      )}
    </form>
  );
};

export default NewMesaage;
