import React from "react";
import globalStyles from "../../global/globalStyles";
import Footer from "../../components/home/Footer";

const Privacy = () => {
  return (
    <div className="w-full flex flex-col pt-[9vh] text-gray-400">
      <div className="w-full bg-why bg-no-repeat bg-cover h-48 overflow-hidden">
        <div className="w-full h-full backdrop-blur p-8 flex items-center justify-start text-4xl lg:text-5xl font-semibold text-white">
          <div
            data-aos="fade-up"
            className="flex w-fit h-fit flex-col gap-3 items-end"
          >
            <span>Privacy Policy</span>
            <div className="w-24 h-1 bg-primary" />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col p-6">
        <h2 className={globalStyles.title}>Overview</h2>
        <p className="font-light text-justify mb-10">
          Welcome to Alpha Assets! This Privacy Policy explains how we collect,
          use, disclose, and protect your personal information when you use our
          website and services. By accessing or using Alpha Assets, you consent
          to the practices described in this Privacy Policy.rategies and
          technologies accessible to investors of all levels.
        </p>
        <div className="w-full flex flex-col">
          <h2 className={globalStyles.title}>Information We Collect</h2>
          <h4 className="font-semibold text-lg text-primary my-1">
            Personal Information
          </h4>
          <p className="font-light text-justify mb-10">
            When you sign up for an account, we may collect personal information
            such as your name, email address, and other relevant details
            necessary for account creation and management.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Financial Information
          </h4>
          <p className="font-light text-justify mb-10">
            To facilitate transactions and provide you with our services, we may
            collect financial information, including bank account details and
            transaction history.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Usage Data
          </h4>
          <p className="font-light text-justify mb-10">
            We may collect information about how you interact with our website,
            including pages visited, time spent on the site, and other usage
            data. This information helps us improve our services and user
            experience.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Device Information
          </h4>
          <p className="font-light text-justify mb-10">
            We may collect information about the device you use to access Alpha
            Assets, including device type, operating system, and browser
            information.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Cookies and Tracking Technologies
          </h4>
          <p className="font-light text-justify mb-10">
            We use cookies and similar tracking technologies to enhance your
            experience on our website. You can control cookie preferences
            through your browser settings.
          </p>
        </div>
        <div className="w-full flex flex-col">
          <h2 className={globalStyles.title}>How We Use Your Information</h2>
          <h4 className="font-semibold text-lg text-primary my-1">
            Providing and Improving Services
          </h4>
          <p className="font-light text-justify mb-10">
            We use your information to provide and improve our services,
            personalize your experience, and respond to your inquiries.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Transaction Processing
          </h4>
          <p className="font-light text-justify mb-10">
            We use financial information to process transactions, including
            deposits, withdrawals, and trades.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Security and Fraud Prevention
          </h4>
          <p className="font-light text-justify mb-10">
            We employ security measures to protect your information and may use
            it to prevent and detect fraudulent activities.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Communication
          </h4>
          <p className="font-light text-justify mb-10">
            We may use your contact information to send you updates,
            newsletters, and other relevant communications. You can opt-out of
            marketing communications at any time.
          </p>
        </div>
        <h2 className={globalStyles.title}>Information Sharing</h2>
        <p className="font-light text-justify mb-10">
          We do not sell, trade, or rent your personal information to third
          parties. However, we may share information with trusted service
          providers who assist us in operating our website and providing
          services, subject to confidentiality agreements.
        </p>
        <div className="w-full flex flex-col">
          <h2 data-aos="fade-right" className={globalStyles.title}>
            Your Choices
          </h2>
          <h4 className="font-semibold text-lg text-primary my-1">
            Account Information
          </h4>
          <p className="font-light text-justify mb-10">
            You can review and update your account information by logging into
            your Alpha Assets account.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">
            Marketing Communications
          </h4>
          <p className="font-light text-justify mb-10">
            You can opt-out of receiving marketing communications by following
            the unsubscribe instructions provided in the emails.
          </p>
          <h4 className="font-semibold text-lg text-primary my-1">Cookies</h4>
          <p className="font-light text-justify mb-10">
            You can manage cookie preferences through your browser settings.
          </p>
        </div>
        <h2 className={globalStyles.title}>Data Security</h2>
        <p className="font-light text-justify mb-10">
          We implement industry-standard security measures to protect your
          personal information. However, no method of transmission over the
          internet or electronic storage is entirely secure. Please take
          appropriate precautions to protect your information.
        </p>
        <h2 className={globalStyles.title}>Contact Us</h2>
        <p className="font-light text-justify mb-10">
          If you have questions, concerns, or requests regarding this Privacy
          Policy, please contact us at support@alphaassets.biz.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
