import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Spinner from "../spinners/Spinner";
import Modal from "./Modal";
import globalStyles from "../../global/globalStyles";

const PlanModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [roi, setRoi] = useState("");
  const [duration, setDuration] = useState("");
  const [min_amount, setMin_amount] = useState("");
  const [max_amount, setMax_amount] = useState("");
  const details = {
    title,
    roi,
    duration,
    min_amount,
    max_amount,
  };

  const handle = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/create-plan", details);
      setLoading(false);
      console.log(res.data);
      window.location.reload();
      toast.success(res.data.message);
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) {
        Object.keys(error.response.data.message).forEach((key) => {
          toast.error(error.response.data.message[key][0]);
        });
      }
      console.log(error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <button onClick={openModal} className={globalStyles.button}>
        Add plan
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form onSubmit={handle} className={globalStyles.formModal}>
          <h1 className="text-xl font-semibold text-primary">Create plan</h1>
          <input
            type="text"
            className={globalStyles.input}
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <input
            type="text"
            className={globalStyles.input}
            placeholder="ROI"
            value={roi}
            onChange={(e) => setRoi(e.target.value)}
          />
          <input
            type="number"
            className={globalStyles.input}
            placeholder="Duration"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
          <input
            type="number"
            className={globalStyles.input}
            placeholder="Minimum amount"
            value={min_amount}
            onChange={(e) => setMin_amount(e.target.value)}
          />
          <input
            type="number"
            className={globalStyles.input}
            placeholder="Maximum amount"
            value={max_amount}
            onChange={(e) => setMax_amount(e.target.value)}
          />
          {loading ? (
            <Spinner />
          ) : (
            <button className={globalStyles.buttonFull}>Create</button>
          )}
        </form>
      </Modal>
    </>
  );
};

export default PlanModal;
