import { useEffect, useState } from 'react'
import api from '../apis/api';

const useFetchPlan = () => {
  const [plan, setPlan] = useState([]);
  const [loadPlan, setLoadPlan] = useState(false);
  const getPlan = async () => {
    setLoadPlan(true);
    try {
      const res = await api.get("/all-plan");
      setPlan(res.data);
      setLoadPlan(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlan();
  }, []);
  return { plan, loadPlan };
}

export default useFetchPlan