import React, { useState } from "react";
import api from "../../apis/api";
import { toast } from "react-toastify";
import Spinner from "../spinners/Spinner";
import globalStyles from "../../global/globalStyles";

const HandleMessage = ({ newMessage, id, setMessagePresent }) => {
  const [loading, setloading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      const res = await api.post(`/delete-display-message/${id}`);
      toast.success(res.data.message);
      setMessagePresent(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="w-full p-5 bg-gray-50 flex flex-col gap-2 items-center z-10">
      <h2 className="text-xl font-semibold text-primary">Handle Message</h2>
      <div className="w-full flex gap-5 items-center">
        <div className="w-3/4 text-primary text-xs">{newMessage.message}</div>
        <div className="w-1/4 flex justify-center">
          {" "}
          {loading ? (
            <Spinner />
          ) : (
            <form className="w-full" onSubmit={handleSubmit}>
              <button className={globalStyles.buttonFull}>
                <span>Delete</span> <i className="fa-solid fa-trash"></i>
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default HandleMessage;
