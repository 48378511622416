import React from "react";
import { Link } from "react-router-dom";
import Logo from "../logos/br.png";

const Footer = () => {
  return (
    <div className="w-full flex flex-col gap-5 p-5 bg-gray-50 z-10">
      <div className="w-full grid grid-cols-4 gap-5 max-lg:grid-cols-2">
        <div className="w-full col-span-2 flex flex-col gap-1">
          <Link to={`/`} className="flex items-center gap-1">
            <img src={Logo} alt="" className="w-10 h-10" />
            <span className="text-primary font-semibold">Alpha Assets</span>
          </Link>
          <p className="text-xs text-gray-400 lg:w-3/4">
            Unlock the power of AI-driven investing with Alpha Assets.
            Seamlessly trade cryptocurrencies and stocks, guided by cutting-edge
            technology and financial expertise. Elevate your financial journey
            with our data-driven approach to smarter, more informed investments.
          </p>
        </div>
        <div className="w-full flex flex-col gap-2">
          <h3 className="font-semibold text-primary">Useful links</h3>
          <Link
            to={`/`}
            className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
          >
            <i className="fa-solid fa-caret-right text-primary/40"></i>
            <span className="text-gray-400">Home</span>
          </Link>
          <Link
            to={`/about-us`}
            className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
          >
            <i className="fa-solid fa-caret-right text-primary/40"></i>
            <span className="text-gray-400">About Us</span>
          </Link>
          <Link
            to={`/our-services`}
            className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
          >
            <i className="fa-solid fa-caret-right text-primary/40"></i>
            <span className="text-gray-400">Our Services</span>
          </Link>
          <Link
            to={`/terms-and-condition`}
            className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
          >
            <i className="fa-solid fa-caret-right text-primary/40"></i>
            <span className="text-gray-400">Term & Condition</span>
          </Link>
          <Link
            to={`/privacy-policy`}
            className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
          >
            <i className="fa-solid fa-caret-right text-primary/40"></i>
            <span className="text-gray-400">Privacy Policy</span>
          </Link>
        </div>
        <div className="w-full flex flex-col gap-2">
          <h3 className="font-semibold text-primary">Get in touch with us</h3>
          <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
            <i className="fa-solid fa-phone text-primary/40"></i>
            <span className="text-gray-400">+44 7402 138402</span>
          </div>
          <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
            <i className="fa-solid fa-envelope text-primary/40"></i>
            <span className="text-gray-400"> support@alphaassets.biz</span>
          </div>
          <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
            <i className="fa-solid fa-location text-primary/40"></i>
            <span className="text-gray-400">
              45 Viaduct Rd, Chelmsford, Essex, United Kingdom, CM1 1TS
            </span>
          </div>
        </div>
      </div>
      <span className="w-full text-center text-primary text-xss">
        © 2024 Alpha Assets inc. All rights reserved.
      </span>
      <span className="w-full flex justify-center gap-5 text-primary text-xs">
        <i className="fa-brands fa-square-facebook"></i>
        <i className="fa-brands fa-instagram"></i>
        <i className="fa-brands fa-x-twitter"></i>
      </span>
    </div>
  );
};

export default Footer;
