const WalletApps = [
  "Abra",
  "ArcBit",
  "Atomic Wallet",
  "BitBox",
  "BitGo",
  "BitLox",
  "Bitnovo",
  "BitPay",
  "Bitwala",
  "Blockchain.com Wallet",
  "BRD",
  "Coin.space",
  "Coinbase Wallet",
  "CoinEx",
  "Coinomi",
  "CoinPouch",
  "CoolWallet S",
  "Edge",
  "Electrum",
  "Exodus",
  "GreenAddress",
  "Guarda Wallet",
  "Guardarian",
  "Huobi Wallet",
  "Infinito Wallet",
  "Jaxx Liberty",
  "KeepKey",
  "Ledger Live",
  "LoafWallet",
  "Lumi Wallet",
  "Lykke Wallet",
  "MetaMask",
  "Mycelium",
  "MyEtherWallet",
  "Nexo Wallet",
  "NinjaCoin",
  "Rahakott",
  "Samourai Wallet",
  "TokenPocket",
  "Trezor Wallet",
  "Trust Wallet",
  "Trustee Wallet",
  "Unstoppable Wallet",
  "Xapo Wallet",
  "ZenGo",
];

export default WalletApps;
