import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Spinner from "../spinners/Spinner";
import globalStyles from "../../global/globalStyles";

const ChargeFee = ({ id }) => {
  const [loading2, setLoading2] = useState(false);
  const [charge, setCharge] = useState("");
  const chargeUser = async (e) => {
    e.preventDefault();
    setLoading2(true);
    const user_id = id;
    const amount = charge;
    try {
      const res = await api.post("/create-transaction-charge", {
        amount,
        user_id,
      });
      setLoading2(false);
      window.location.reload();
      setCharge("");
      toast.success(res.data.message);
      toast.success("Charge succesful");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setLoading2(false);
    }
  };
  return (
    <form onSubmit={chargeUser} className={globalStyles.formModal}>
      <h1 className="text-xl font-semibold text-primary">Charge User</h1>
      <input
        type="number"
        className={globalStyles.input}
        placeholder="Amount"
        value={charge}
        onChange={(e) => setCharge(e.target.value)}
      />
      {loading2 ? (
        <Spinner />
      ) : (
        <button className={globalStyles.buttonFull}>Charge fee</button>
      )}
    </form>
  );
};

export default ChargeFee;
