import React from "react";
import api from "../../apis/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import globalStyles from "../../global/globalStyles";

const ViewStatus = ({ profile, setLoadUser, getProfile }) => {
  const navigate = useNavigate();
  const setLevel = (level) => {
    if (level === 1) {
      return "I";
    } else if (level === 2) {
      return "II";
    } else {
      return "III";
    }
  };

  const denyVerification = async (e) => {
    e.preventDefault();
    setLoadUser(true);
    try {
      const res = await api.post(`/delete-level-3-verification/${profile.id}`);
      toast.success(res.data.message);
      setLoadUser(false);
      navigate(-1);
    } catch (error) {
      setLoadUser(false);
      toast.error(error.response.data.message);
    }
  };

  const approveVerification = async (e) => {
    e.preventDefault();
    setLoadUser(true);
    try {
      await api.post(`/approve-level-3-verification/${profile.id}`);
      toast.success("Profile fully upgraded");
      setLoadUser(false);
      getProfile();
    } catch (error) {
      setLoadUser(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="w-full p-5 bg-gray-50 flex flex-col gap-2 items-center z-10">
      <h1 className="text-xl font-semibold text-primary capitalize">
        Account status
      </h1>
      <div className="w-full flex justify-between items-center mb-2">
        <span className="text-xxs text-start text-primary">Account Tier</span>
        <div className="flex items-center justify-center relative -translate-x-4">
          <div className="absolute rounded-full bg-primary w-6 h-6 flex items-center justify-center text-white font-bold text-lg left-0 z-10 shadow">
            {profile.level}
          </div>
          <div className="w-14 h-4 bg-primary/30 rounded-r-full translate-x-4 z-0 flex items-center justify-center text-primary text-xss shadow">
            {" "}
            <span>Tier {setLevel(parseInt(profile.level))}</span>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between items-center mb-2">
        <span className="text-xxs text-start text-primary">
          Email Verification
        </span>
        {parseInt(profile.level) === 2 || parseInt(profile.level) === 3 ? (
          <div className="px-2 py-0.5 flex items-center cursor-pointer shadow rounded-full text-xxs text-primary bg-primary/30">
            Email verified
          </div>
        ) : (
          <div className="px-2 py-0.5 flex items-center cursor-pointer shadow rounded-full text-xxs text-primary bg-primary/30">
            Email unverified
          </div>
        )}
      </div>
      {parseInt(profile.level) === 3 ? (
        ""
      ) : (
        <div className="w-full flex gap-2">
          <form onSubmit={approveVerification} className="w-full">
            <button className={globalStyles.buttonFull}>Approve</button>
          </form>
          <form onSubmit={denyVerification} className="w-full">
            <button className={globalStyles.buttonFull}>Reject</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ViewStatus;
