import React from "react";

function ShortenString({ text, maxLength }) {
  if (text.length <= maxLength) {
    return <span>{text}</span>;
  } else {
    const shortenedText = text.substring(0, maxLength) + "...";
    return <span>{shortenedText}</span>;
  }
}

export default ShortenString;
