import React, { useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../../components/spinners/Spinner";
import Modal from "../../components/modals/Modal";
import api from "../../apis/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import globalStyles from "../../global/globalStyles";

const Withdraw = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("BTC");
  const profile = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const payment_method = paymentMethod;
  const user_id = profile.id;
  const type = "withdrawal";
  const transaction = {
    address,
    amount,
    user_id,
    payment_method,
    type,
  };

  const save = () => {
    openModal();
  };

  const makeWithdrawal = (e) => {
    e.preventDefault();
    if (amount === "") {
      toast.warning("Please enter an amount to proceed");
    } else if (amount > parseFloat(profile.wallet)) {
      toast.warning(
        "You do not have up to the amount you are trying to withdraw from your wallet"
      );
    } else {
      if (parseInt(profile.level) === 1 && amount > 2000) {
        toast.warning("Maximum withdrawal for Tier I is $2,000");
      } else if (parseInt(profile.level) === 2 && amount > 10000) {
        toast.warning("Maximum withdrawal for Tier II is $10,000");
      } else {
        save();
      }
    }
  };

  const withdrawFunds = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post("/create-transaction-wit", transaction);
      setLoading(false);
      navigate("/dashboard");
      toast.info("Withdrawal processing.");
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={globalStyles.pageDashboardScreen}>
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold text-primary">
            Withdraw Funds
          </span>
        </div>
        {/*body*/}

        <div className="w-full h-full flex justify-center items-center">
          <form onSubmit={makeWithdrawal} className={globalStyles.form}>
            <h1 className="text-xl font-semibold text-primary">
              Make a withdrawal
            </h1>
            <input
              type="number"
              className={globalStyles.input}
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              className={globalStyles.inputSelect}
            >
              <option value="Bitcoin">Bitcoin</option>
              <option value="Ripple">Ripple</option>
              <option value="Litecoin">Litecoin</option>
              <option value="Ethereum">Ethereum</option>
            </select>
            <button className={globalStyles.buttonFull}>Confirm</button>
          </form>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form onSubmit={withdrawFunds} className={globalStyles.formModal}>
          <h1 className={globalStyles.title}>Enter address</h1>
          <input
            type="text"
            className={globalStyles.input}
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <div className="bg-gray-50 text-primary flex gap-2 items-center p-2 w-full">
            <i className="fa-solid fa-info text-xs"></i>
            <p className="text-xxs leading-3 text-justify">
              {paymentMethod === "BTC"
                ? "Please provide your bitcoin address which you would use to recieve payment."
                : "Please provide your wallet address which you would use to recieve payment. Please note the address provided must the wallet you selected"}
            </p>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <button className={globalStyles.buttonFull}>
              Withdraw{" "}
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(amount)}
            </button>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default Withdraw;
