import React, { useEffect, useState } from 'react'
import api from '../../apis/api';

const TotalDeposit = () => {
      const [deposit, setDeposit] = useState("");
      const getDeposits = async () => {
        try {
          const res = await api.get("/user-total-deposits");
          setDeposit(res.data);
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        getDeposits();
      }, []);
  return (
    <div className="w-full h-20 bg-gray-50 flex px-8 items-center justify-between">
      <i className="fa-solid fa-chart-line text-3xl text-primary"></i>
      <div className="flex flex-col items-end">
        <h5 className="text-primary font-semibold text-xs">Total Deposits</h5>
        <h5 className="text-gray-400 font-extralight text-xl">
          {deposit === ""
            ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(0)
            : new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(deposit)}
        </h5>
      </div>
    </div>
  );
}

export default TotalDeposit