import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../apis/api";

const useFetchMe = () => {
  const [profile, setProfile] = useState([]);
  const [loadUser, setLoadUser] = useState(false);
  const navigate = useNavigate();
  const token = !!localStorage.getItem("access_token");
  const getUser = async () => {
    setLoadUser(true);
    try {
      const res = await api.post("/me");
      setProfile(res.data);
      setLoadUser(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Unauthenticated.") {
        localStorage.removeItem("access_token");
        navigate("/");
      }
    }
  };
  useEffect(() => {
    if (token) {
      getUser();
    }
    // eslint-disable-next-line
  }, []);

  return { profile, loadUser };
};

export default useFetchMe;
