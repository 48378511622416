import React from 'react'
import { Link } from 'react-router-dom';
import globalStyles from '../../global/globalStyles';

const StatusWidget = ({ profile }) => {
      const setLevel = (level) => {
        if (level === 1) {
          return "I";
        } else if (level === 2) {
          return "II";
        } else {
          return "III";
        }
      };
      const handleLevel = (level) => {
        if (level === 1) {
          return (
            <Link
              to={`/verify-email-address`}
              className={globalStyles.buttonFullFlex}
            >
              Verify email
            </Link>
          );
        } else if (level === 2 && profile.ssn === null) {
          return (
            <Link
              to={`/verify-your-identity`}
              className={globalStyles.buttonFullFlex}
            >
              Upgrade to Tier III
            </Link>
          );
        } else if (level === 2 && profile.ssn !== null) {
          return (
            <button
              disabled
              className={globalStyles.buttonFullFlex}>
              Upgrade to Tier III (pending)
            </button>
          );
        } else {
          return (
            <button disabled className={globalStyles.buttonFullFlex}>
              Max Level
            </button>
          );
        }
      };
  return (
    <div className="w-full p-5 bg-gray-50 flex flex-col gap-2 items-center z-10">
      <h1 className="text-xl font-semibold text-primary capitalize">Account status</h1>
      <div className="w-full flex justify-between items-center mb-2">
        <span className="text-xxs text-start text-primary">Account Tier</span>
        <div className="flex items-center justify-center relative -translate-x-4">
          <div className="absolute rounded-full bg-primary w-6 h-6 flex items-center justify-center text-white font-bold text-lg left-0 z-10 shadow">
            {profile.level}
          </div>
          <div className="w-14 h-4 bg-primary/30 rounded-r-full translate-x-4 z-0 flex items-center justify-center text-primary text-xss shadow">
            {" "}
            <span>Tier {setLevel(parseInt(profile.level))}</span>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between items-center mb-2">
        <span className="text-xxs text-start text-primary">
          Email Verification
        </span>
        {parseInt(profile.level) === 2 || parseInt(profile.level) === 3 ? (
          <div className="px-2 py-0.5 flex items-center cursor-pointer shadow rounded-full text-xxs text-primary bg-primary/30">
            Email verified
          </div>
        ) : (
          <div className="px-2 py-0.5 flex items-center cursor-pointer shadow rounded-full text-xxs text-primary bg-primary/30">
            Email unverified
          </div>
        )}
      </div>
      {handleLevel(parseInt(profile.level))}
    </div>
  );
}

export default StatusWidget