import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../apis/api";
import { toast } from "react-toastify";
import Spinner from "../../components/spinners/Spinner";
import globalStyles from "../../global/globalStyles";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [hidden, sethidden] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const user = {
    username,
    password,
  };

  const logIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/login", user);
      setLoading(false);
      toast.info(res.data.message);
      const token = res.data.access_token;
      localStorage.setItem("access_token", token);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error);
      console.log(error);
    }
  };
  return (
    <div className="w-full h-screen pt-[9vh] flex justify-center items-center bg-white -z-10">
      <form onSubmit={logIn} className={globalStyles.form}>
        <h1 className="text-xl font-semibold text-primary">Log in</h1>
        <input
          type="text"
          className={globalStyles.input}
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type={hidden ? "password" : "text"}
          className={globalStyles.input}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="w-full flex justify-end">
          {hidden ? (
            <div
              onClick={() => sethidden(!hidden)}
              className="flex items-center gap-1 text-primary text-xs cursor-pointer"
            >
              <p className="">Show password</p>
              <i class="fa-regular fa-eye"></i>
            </div>
          ) : (
            <div
              onClick={() => sethidden(!hidden)}
              className="flex items-center gap-1 text-primary text-xs cursor-pointer"
            >
              <p className="">Hide password</p>
              <i class="fa-regular fa-eye-slash"></i>
            </div>
          )}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <button className={globalStyles.buttonFull}>Log in</button>
        )}
        <div className="w-full flex justify-start text-primary text-xs">
          <Link to={`/send-reset-code`}>Forgot Password?</Link>
        </div>
        <div className="w-full gap-5 flex items-center justify-between">
          <hr className="w-full border-primary" />
          <p className="text-primary text-xs">or</p>
          <hr className="w-full border-primary" />
        </div>
        <p className="text-xs text-gray-500">
          Don't have an account?{" "}
          <Link to="/signup" className="text-primary">
            Signup here
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
