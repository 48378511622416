import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import UserDropdown from "../dropdowns/UserDropdown";
import Logo from "../logos/br.png";
import globalStyles from "../../global/globalStyles";

const HomeNavbar = () => {
  const token = !!localStorage.getItem("access_token");
  const guestMenu = [
    { title: "home", link: "/" },
    { title: "about us", link: "/about-us" },
    { title: "our services", link: "/our-services" },
    { title: "t&c", link: "/terms-and-condition" },
    { title: "privacy policy", link: "/privacy-policy" },
  ];
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false); // Close the menu if clicked outside the navbar
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav
      ref={navbarRef}
      className="h-fit w-full fixed bg-white flex flex-col z-20"
    >
      <div className="w-full flex justify-between items-center h-[9vh] px-5">
        <Link to={`/`} className="flex items-center gap-1">
          <img src={Logo} alt="" className="w-10 h-10" />
          <span className="text-primary font-semibold">Alpha Assets</span>
        </Link>
        <ul className="gap-5 text-sm flex text-primary max-lg:hidden capitalize transition-all ease-in-out">
          {guestMenu.map((item) => {
            return (
              <Link
                to={item.link}
                key={item.title}
                className={`${
                  location.pathname === item.link
                    ? "scale-110 text-primary/80 font-semibold"
                    : ""
                } transition-all ease-in-out`}
              >
                {item.title}
              </Link>
            );
          })}
        </ul>
        {token ? (
          <ul className="gap-5 text-sm flex">
            <UserDropdown />
            <div
              onClick={toggleMenu}
              className={`w-6 h-6 max-lg:flex flex-col justify-between hidden z-50 transition-all ease-in-out items-start `}
            >
              <span
                className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                  isOpen ? "rotate-45 translate-y-2.5" : ""
                }`}
              ></span>
              <span
                className={`w-5 h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                  isOpen ? "opacity-0" : ""
                }`}
              ></span>
              <span
                className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                  isOpen ? "-rotate-45 -translate-y-3" : ""
                }`}
              ></span>
            </div>
          </ul>
        ) : (
          <ul className="gap-5 text-sm flex">
              <Link to="/signup" className={globalStyles.button2}>
                Sign Up
              </Link>
            <Link to="/login" className={globalStyles.button}>
              Log in
            </Link>
            <div
              onClick={toggleMenu}
              className={`w-6 h-6 max-lg:flex flex-col justify-between hidden z-50 transition-all ease-in-out items-start `}
            >
              <span
                className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                  isOpen ? "rotate-45 translate-y-2.5" : ""
                }`}
              ></span>
              <span
                className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                  isOpen ? "opacity-0" : ""
                }`}
              ></span>
              <span
                className={`w-full h-0.5 bg-primary transition-all ease-in-out duration-1000 ${
                  isOpen ? "-rotate-45 -translate-y-3" : ""
                }`}
              ></span>
            </div>
          </ul>
        )}
      </div>
      <div
        className={`${
          isOpen ? "" : ""
        } transition-all ease-in-out max-lg:flex hidden flex-col`}
      >
        {guestMenu.map((item) => {
          return (
            <Link
              to={item.link}
              key={item.title}
              onClick={() => setIsOpen(false)}
              className={`w-full ${
                isOpen ? "h-10 border-b" : "h-0"
              } flex transition-all border-primary/20 ease-in-out bg-white backdrop-blur-sm items-center px-5 text-primary capitalize`}
            >
              {isOpen ? item.title : null}
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default HomeNavbar;
