import React, { useEffect, useState } from "react";
import api from "../../apis/api";

const TotalWithdrawal = () => {
    const [withdrawal, setWithdrawal] = useState("");
    const getWithdrawal = async () => {
      try {
        const res = await api.get("/user-total-withdrawals");
        setWithdrawal(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getWithdrawal();
    }, []);
  return (
    <div className="w-full h-20 bg-gray-50 flex px-8 items-center justify-between">
      <i className="fa-solid fa-chart-line text-3xl text-primary"></i>
      <div className="flex flex-col items-end">
        <h5 className="text-primary font-semibold text-xs">
          Total Withdrawals
        </h5>
        <h5 className="text-gray-400 font-extralight text-xl">
          {withdrawal === ""
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(0)
            : new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(withdrawal)}
        </h5>
      </div>
    </div>
  );
};

export default TotalWithdrawal;
