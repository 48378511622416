import React from "react";
import Logo from "../logos/br.png"

const Spinner = () => {
  return (
    <div className="loader-comet relative w-20 h-20 flex items-center justify-center">
      <div className="comet absolute w-1 h-1 bg-primary rounded-full"></div>
      <img src={Logo} alt="" className="w-10 h-10 animate-pulse"/>
    </div>
  );
};

export default Spinner;
