const Occupations = [
  "Accountant",
  "Actor",
  "Architect",
  "Artist",
  "Athlete",
  "Author",
  "Baker",
  "Barista",
  "Bartender",
  "Biologist",
  "Carpenter",
  "Chef",
  "Chemist",
  "Chiropractor",
  "Civil Engineer",
  "Computer Programmer",
  "Dentist",
  "Detective",
  "Dietitian",
  "Economist",
  "Electrician",
  "Fashion Designer",
  "Film Director",
  "Financial Analyst",
  "Firefighter",
  "Flight Attendant",
  "Geologist",
  "Graphic Designer",
  "Hair Stylist",
  "Historian",
  "Interior Designer",
  "Journalist",
  "Judge",
  "Landscape Architect",
  "Librarian",
  "Linguist",
  "Magician",
  "Mechanical Engineer",
  "Meteorologist",
  "Musician",
  "Nurse",
  "Nutritionist",
  "Optometrist",
  "Painter",
  "Paramedic",
  "Pharmacist",
  "Photographer",
  "Physicist",
  "Pilot",
  "Plumber",
  "Police Officer",
  "Politician",
  "Professor",
  "Psychiatrist",
  "Real Estate Agent",
  "Receptionist",
  "Research Scientist",
  "Singer",
  "Social Worker",
  "Software Developer",
  "Speech Therapist",
  "Surgeon",
  "Teacher",
  "Translator",
  "Urban Planner",
  "Veterinarian",
  "Waiter/Waitress",
  "Web Developer",
  "Writer",
  "Others",
];

export default Occupations;
