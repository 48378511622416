import React from "react";
import bitcoin from "../../components/images/cryptoImage.jpg";
import stock from "../../components/images/stockImage.jpg";
import portfolio from "../../components/images/portImage.png";
import support from "../../components/images/supportImage.png";
import globalStyles from "../../global/globalStyles";
import Footer from "../../components/home/Footer";

const Services = () => {
  return (
    <div className="w-full flex flex-col pt-[9vh] list-decimal overflow-hidden text-gray-400">
      <div className="w-full bg-why bg-no-repeat bg-cover h-48 overflow-hidden">
        <div className="w-full h-full backdrop-blur p-8 flex items-center justify-start text-4xl lg:text-5xl font-semibold text-white">
          <div
            data-aos="fade-up"
            className="flex w-fit h-fit flex-col gap-3 items-end"
          >
            <span>Our Services</span>
            <div className="w-24 h-1 bg-primary" />
          </div>
        </div>
      </div>
      <div className="w-full p-6 flex flex-col">
        <p className="font-light text-justify mb-10">
          At Alpha Assets, we offer a range of services designed to elevate your
          investment experience. Our cutting-edge technology and AI-driven
          approach ensure that you can seamlessly navigate the worlds of
          cryptocurrency and stock trading with confidence.
        </p>
        <h2 data-aos="fade-right" className={globalStyles.homeTitle}>
          1. Cryptocurrency Trading
        </h2>
        <div className="flex max-lg:flex-col w-full lg:gap-6 mb-20 mt-5">
          <div className="p-4 w-full lg:w-1/2">
            <img
              data-aos="fade-right"
              src={bitcoin}
              alt=""
              className="shadow w-full h-full"
            />
          </div>
          <div className="flex flex-col w-full lg:w-1/2">
            <h4 data-aos="fade-left" className={globalStyles.title}>
              Experience the Future of Finance
            </h4>
            <p data-aos="fade-up" className="font-light text-justify mb-4">
              Unlock the potential of the ever-evolving cryptocurrency market
              with Alpha Assets. Our AI bots are equipped to analyze the dynamic
              nature of cryptocurrencies, providing you with opportunities to
              capitalize on market trends and maximize returns.
            </p>
            <h4 className={globalStyles.title}>Key Features:</h4>
            <ul className="list-outside font-light leading-8">
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">
                  Diversified Portfolios:{" "}
                </span>
                Invest in a range of cryptocurrencies to spread risk and
                optimize returns.
              </li>
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">
                  Real-Time Analysis:{" "}
                </span>
                Stay ahead with real-time market analysis and insights.
              </li>
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">
                  Secure Transactions:{" "}
                </span>
                Benefit from secure and encrypted cryptocurrency transactions.
              </li>
            </ul>
          </div>
        </div>
        <h2 data-aos="fade-right" className={globalStyles.homeTitle}>
          2. Stock Trading
        </h2>
        <div className="flex max-lg:flex-col lg:flex-row-reverse w-full lg:gap-6 mb-20 mt-5">
          <div className="p-4 w-full lg:w-1/2">
            <img
              data-aos="fade-left"
              src={stock}
              alt=""
              className="shadow w-full h-full"
            />
          </div>
          <div className="flex flex-col w-full lg:w-1/2">
            <h4 data-aos="fade-right" className={globalStyles.title}>
              Navigate Traditional Markets with Ease
            </h4>
            <p data-aos="fade-up" className="font-light text-justify mb-4">
              Dive into the world of traditional finance with Alpha Assets'
              stock trading services. Our AI algorithms are finely tuned to
              navigate the complexities of stock markets, making investment
              decisions that align with your financial goals.
            </p>
            <h4 className={globalStyles.title}>Key Features:</h4>
            <ul className="list-outside font-light leading-8">
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">
                  Intelligent Decision-Making:{" "}
                </span>
                Let our AI bots make informed stock trading decisions based on
                extensive market analysis.
              </li>
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">
                  Customizable Strategies:{" "}
                </span>
                Tailor your investment strategy to match your risk tolerance and
                investment preferences.
              </li>
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">
                  Continuous Monitoring:{" "}
                </span>
                Stay informed with 24/7 monitoring of stock market trends.
              </li>
            </ul>
          </div>
        </div>
        <h2 data-aos="fade-right" className={globalStyles.homeTitle}>
          3. Integrated Portfolio Management
        </h2>
        <div className="flex max-lg:flex-col w-full lg:gap-6 mb-20 mt-5">
          <div className="p-4 w-full lg:w-1/2">
            <img
              data-aos="fade-right"
              src={portfolio}
              alt=""
              className="w-full h-full"
            />
          </div>
          <div className="flex flex-col w-full lg:w-1/2">
            <h4 data-aos="fade-left" className={globalStyles.title}>
              Your Investments, Your Control
            </h4>
            <p data-aos="fade-up" className="font-light text-justify mb-4">
              Alpha Assets puts you in the driver's seat with our integrated
              portfolio management services. Manage your crypto and stock
              investments in one centralized platform, providing you with a
              holistic view of your financial portfolio.
            </p>
            <h4 className={globalStyles.title}>Key Features:</h4>
            <ul className="list-outside font-light leading-8">
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">
                  Single Dashboard:{" "}
                </span>
                Monitor you portfolios seamlessly on one user-friendly
                dashboard.
              </li>
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">
                  Performance Tracking:{" "}
                </span>
                Keep track of the performance of your investments with detailed
                analytics.
              </li>
            </ul>
          </div>
        </div>
        <h2 data-aos="fade-right" className={globalStyles.homeTitle}>
          4. Customer Support
        </h2>
        <div className="flex max-lg:flex-col lg:flex-row-reverse w-full lg:gap-6 mb-20 mt-5">
          <div className="p-4 w-full lg:w-1/2 flex items-center justify-center">
            <img
              data-aos="fade-left"
              src={support}
              alt=""
              className="w-40 h-40"
            />
          </div>
          <div className="flex flex-col w-full lg:w-1/2">
            <h4 data-aos="fade-right" className={globalStyles.title}>
              Your Partner in Success
            </h4>
            <p data-aos="fade-up" className="font-light text-justify mb-4">
              Our commitment to your success goes beyond technology. Our
              dedicated customer support team is here to assist you at every
              step. Whether you have questions about our services or need
              guidance on your investment journey, we're just a message away.
            </p>
            <h4 className={globalStyles.title}>Key Features:</h4>
            <ul className="list-outside font-light leading-8">
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">
                  Responsive Support:{" "}
                </span>
                Reach out to our support team for prompt and helpful assistance.
              </li>
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">FAQs: </span>
                Access a comprehensive knowledge base to find answers to common
                queries.
              </li>
              <li data-aos="fade-up">
                <span className="text-primary font-semibold">Live Chat: </span>
                Connect with us in real-time through our live chat feature for
                immediate support.
              </li>
            </ul>
          </div>
        </div>
        <p className="font-light text-justify mb-4">
          Ready to embark on a journey of smarter, more informed investing?
          Explore our services and take control of your financial future with
          Alpha Assets. Sign up today to experience the next level of AI-driven
          trading!
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
